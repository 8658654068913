import React, { Component } from 'react';

export default function SiteFooter(props) {
	return <div className="site-footer">
		<div className="logos">
			<a href="http://www.arnastofnun.is/"><img src="/img/logo-sam.svg" /></a>
		</div>
		<div className="footer-text">© Stofnun Árna Magnússonar í íslenskum fræðum</div>
	</div>;
}
